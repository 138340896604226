import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Container, Box, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from "../components/layout";
import NotaCard from "../components/NotaCard";
import NotaCardBig from "../components/NotaCardBig";
import NotaCardChica from "../components/NotaCardChica";
import Nota from "../components/Nota";
import { navigate } from "gatsby"
import SEOHome from "../components/SEOHome";
import FacebookPlayer from 'react-facebook-player';
import VideoCard from "../components/VideoCarousel";
import VideoGraph from "../components/VideoGraph";

import bannerAgenda from '../assets/banners/agenda/agenda.jpeg'
import bannerAgenda2 from '../assets/banners/agenda/yolanda.jpeg';

const useStyles = makeStyles(theme => ({
  boxPrincipal:{
    [theme.breakpoints.up('lg')]: {
      marginTop:"2.5rem",
    },
  },
  leftBorder:{
    [theme.breakpoints.up('lg')]: {
      borderLeft:"2px solid black",
    },
  },
  grillaNotaCardElement:{
    [theme.breakpoints.down('sm')]: {
    paddingLeft:'0rem !important',
    paddingRight:'0rem !important',
    },
  },
  boxGrillaNotaCardElement:{
    [theme.breakpoints.up('lg')]: {
      paddingRight:"1rem",
      paddingLeft:"2rem",
      paddingTop:"1rem",
    }
  },
  segundaFilaGrillaNotaCardElement:{
    [theme.breakpoints.up('md')]: {
      marginTop:'2rem',
    }
  },
  boxGrillaNotaCardBigElement:{
    marginRight:"2rem",
    marginLeft:"2rem",
    marginTop:"3rem",
    [theme.breakpoints.down('lg')]: {
      marginRight:"1.5rem",
      marginLeft:"1.5rem",
      marginTop:"2.3rem",
    },
    [theme.breakpoints.down('md')]: {
      marginRight:"1rem",
      marginLeft:"1rem",
      marginTop:"1.1rem",
      marginBottom:'1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight:"0rem",
      marginLeft:"0rem",
      marginTop:"0.3rem",
    },
  },
  boxGrillaNotaCardChicas:{
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      borderTop:"2px solid black",
    }
  },
  bannerDiv:{
    position: 'relative',
    //backgroundColor: '#e20613',
  },
  bannerPDF:{
    marginBottom:'-0.3rem',
    [theme.breakpoints.up('md')]: {
      width:"90%",
    },
    [theme.breakpoints.up('xl')]: {
      width:"100%",
    },
    [theme.breakpoints.down('sm')]: {
      width:"20.3rem",
      height: 'auto',
    },
  },
  bannerIMG:{
    left: '6.1rem',
    top: '-1.4rem',
    height: '18.1rem',
    width: 'auto',
    position: 'absolute',
    '-webkit-transform': 'rotate(-20deg)',
    '-moz-transform': 'rotate(-20deg)',
    '-ms-transform': 'rotate(-20deg)',
    '-o-transform': 'rotate(-20deg)',
    transform: 'rotate(-20deg)',
    [theme.breakpoints.down('lg')]: {
      left: '5.1rem',
      top: '-1.1rem',
      height: '12.9rem',
    },
    [theme.breakpoints.down('md')]: {
      left: '4.1rem',
      top: '-0.9rem',
      height: '9.5rem',
    },
  },
  bannerIMGMobile:{
    top: '-0.3rem',
    left: '0.8rem',
    height: '4.6rem',
    width: 'auto',
    position: 'absolute',
    '-webkit-transform': 'rotate(-20deg)',
    '-moz-transform': 'rotate(-20deg)',
    '-ms-transform': 'rotate(-20deg)',
    '-o-transform': 'rotate(-20deg)',
    transform: 'rotate(-20deg)',
  },
  bannerRow: {
    marginTop: '1rem',
    padding: '0rem 1.5rem 0rem 2.5rem !important',
    [theme.breakpoints.down('sm')]: {
       padding: '0px !important',
       marginTop: '0.5rem',
    },
  },
  bannerAgenda: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  areaLink: {
    cursor: 'pointer',
  },
  gridNotaItem:{
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    }
  },
  boxContenedor:{
    position:'relative',
    textAlign:"center",
    margin:"2rem",
  },
  masNotasTitulo: {
    fontSize: '3rem',
    borderBottom: '2px black solid',
    marginBottom: '2rem'
  },
}));

const videos = [
  {
    link:'https://www.youtube.com/embed/oQdyuPs1jC8',
    title:'Entrevista a Jorge Altamira en Sin Diarios por Somos Radio AM 530 (04/01/25)',
  },
  {
    link:'https://www.youtube.com/embed/PCkB6qUP5Zk',
    title:'Testimonio completo de Yolanda Vargas en Somos como Somos',
  },
  {
    link:'https://www.youtube.com/embed/p-MsrKQvrdc',
    title:' Violeta Gil, en el brindis de fin de año 2024',
  },
  {
    link:'https://www.youtube.com/embed/rLywrnkcg4w',
    title:'El alegato de Pablo Rieznik en el juicio contra genocidas (2009)',
  },
]

/* const bannerData = [
  {
    src: imagenPresidente,
    alt: "Marcelo Ramal presidente, Patricia Urones Vice. Los trabajadores primero! Política Obrera",
  },
  { 
    src: imagenNoa,
    alt: "Raquel Grassino Diputada Tucumán, Valeria Morales Diputada Santiago del Estero, Violeta Gil Diputada Salta. Los trabajadores primero! Política Obrera",  
  },
  {  
    src: imagenPba,
    alt: "Pablo Busch Gobernador de Buenos Aires, Eva Gutiérrez Vice. Los trabajadores primero! Política Obrera",
  },
  {
    src: imagenCaba,
    alt: "Jorge Altamira Diputado Ciudad de Buenos Aires, Valentina Viglieca Jefa de Gobierno, Silvia Allocati Legisladora. Los trabajadores primero! Política Obrera",
  },
]

const bannerData2 = [
  {
    src: verticalRamal,
    alt: "Hay demasiados candidatos del FMI, votemos candidatos socialistas, Marcelo Ramal Presidente",
  },
  {
    src: verticalUrones,
    alt: "Hay demasiados candidatos del FMI, votemos candidatos socialistas, Patricia Urones Vice",
  },
  {
    src: verticalAltamira,
    alt: "Hay demasiados candidatos del FMI, votemos candidatos socialistas, Jorge Altamira Diputado Nacional CABA",
  },
] */

export default ({ data, pageContext }) => {
  const classes = useStyles();
  const [verBanner, setVerBanner] = useState(false);
  const arrayNotas = data.allMarkdownRemark.edges
                      .map(item => item.node)
                      .sort(function(a, b){
                        return pageContext.notasHome.indexOf(a.fields.slug) - pageContext.notasHome.indexOf(b.fields.slug)
                      });

  const ultimas = data.ultimas.edges.map(item => item.node)

  const imgFluidDefault = data.file.childImageSharp.fluid;
  const arrayNotasPrincipales = arrayNotas.slice(0,5);
  const arrayNotasPrincipales2 = arrayNotas.slice(5,11);
  const arrayNotasSecundarias = arrayNotas.slice(11,19);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const flive = false;
  const verVideo = true;
  const verVisualLink = true;

  useEffect(
    () => {
      function refresh(){
        navigate(`/`)
      }
      const intervalo = setInterval(refresh, 600000); // 10min
      return function cleanup() {
        clearInterval(intervalo);;
      };
    }
  );

  function handleCloseBanner(e){
    e.preventDefault();
    setVerBanner(false);
  }

  return (
    <Layout>
      <SEOHome />
      <Box className={classes.boxPrincipal}>
        <Container maxWidth="xl">

          {/* <VideoGraph
            text={"Dora Barrancos por la absolución de Yolanda Vargas"}
            children={
              (
                <video width="auto" height="240" controls>
                  <source
                    src="https://storage.googleapis.com/politicaobrera-187ea.appspot.com/videos/dora-barrancos-absolucion-yolanda-vargas.mp4"
                    type="video/mp4"
                  />
                  Tu browser no soporta html video tag
                </video> 
              )
            }
          /> */}

         {
          flive && (
            <div style={{display: 'flex', justifyContent:'center', marginBottom: '1.5rem'}}>
                <FacebookPlayer
                  appId={'2018114674974892'}
                  videoId={'362894936761860'}
                  id={'f-live-video-player'}
                  allowFullScreen={true}
                  autoplay={false}
                  width={300}
                />
            </div>
          )
         }
         {
          // verBanner && (
          //   <div style={{textAlign:'center', marginBottom: '1rem'}}>
          //     <a key="suscribite-link" target="_blank" rel="noopener norefferer" href="https://api.whatsapp.com/send?phone=5491173600817&text=Me%20quiero%20suscribir%20a%20Pol%C3%ADtica%20Obrera">
          //       <div className={classes.bannerDiv}>
          //         <span onClick={handleCloseBanner} style={{ color:'black' ,position: 'absolute', right: '0.3rem', top: '0.3rem', z:'10', fontSize: matches ? '14px': '24px' }} class="material-icons">
          //           close
          //         </span>
          //         <img className={classes.bannerPDF} src={bannerAgenda} alt="Suscribite a Política Obrera" />
          //       </div>
          //     </a>
          //     {/* <a key="resoluciones-link" target="_blank" rel="noopener norefferer" href="https://politicaobrera.com/tag/segundo-congreso-nacional-politica-obrera">
          //       <div className={classes.bannerDiv}>
          //         <span onClick={handleCloseBanner} style={{ color:'black' ,position: 'absolute', right: '0.3rem', top: '0.3rem', z:'10', fontSize: matches ? '14px': '24px' }} class="material-icons">
          //           close
          //         </span>
          //         <img className={classes.bannerPDF} src={bannerAgenda} alt="Resoluciones del Segundo Congreso de Politica Obrera" />
          //       </div>
          //     </a>    */}
          //     {/* <ImageCarousel key="horizontal" data={bannerData} removeDots={matches}/> */}
          //   </div>
          // )
         }
         {/*BANNER SUPERIOR*/}
         {/* {
          verVisualLink && (
            <VisualLink 
              title={"Por qué votar en blanco"}
              text={"Mirá nuestra elaboración al respecto"}
              link={"https://politicaobrera.com/tag/voto-en-blanco"}
            />
          )
         } */}
          <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
              <Box>
                <Grid container spacing={3}>

                  <Grid key={`nota-1`} item xs={12} md={6} className={classes.grillaNotaCardElement}>
                    <Box className={classes.boxGrillaNotaCardElement}>
                      <NotaCard node={arrayNotasPrincipales[0]} imgFluidDefault={imgFluidDefault} />
                    </Box>
                  </Grid>
                  <Grid key={`nota-2`} item xs={12} md={6} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement].join(' ')} >
                      <NotaCard node={arrayNotasPrincipales[1]} imgFluidDefault={imgFluidDefault} />
                    </Box>
                  </Grid>
                  <Grid key={`nota-3`} item xs={12} md={6} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales[2]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-4`} item xs={12} md={6} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales[3]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>

                  <Grid key={`nota-5`} item xs={12} md={12} className={classes.grillaNotaCardElement}>
                    <Box className={classes.boxGrillaNotaCardBigElement}>
                      <NotaCardBig node={arrayNotasPrincipales[4]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>

                  <Grid key={`nota-6`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[0]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-7`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[1]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-8`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[2]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-9`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[3]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-10`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[4]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>
                  <Grid key={`nota-11`} item xs={12} md={4} className={classes.grillaNotaCardElement}>
                    <Box className={[classes.leftBorder, classes.boxGrillaNotaCardElement,classes.segundaFilaGrillaNotaCardElement].join(' ')}>
                      <NotaCard node={arrayNotasPrincipales2[5]} imgFluidDefault={imgFluidDefault}/>
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3} className={classes.leftBorder}>
              <Box my={1} className={classes.boxGrillaNotaCardChicas}>
                {
                  verVideo && (
                    <VideoCard
                      data={videos}
                      matches={matches}
                    />
                  )
                }

                {/* BANNERS LATERALES */}
                
                <a key="streaming-program" target="_blank" rel="noopener norefferer" href="https://politicaobrera.com/13406-primer-programa-de-streaming-de-politica-obrera-martes-28-de-enero-19-horas">
                  <img className={classes.bannerAgenda} src={bannerAgenda} alt="Programa de streaming de Política Obrera" />
                </a>

                <a key="absolucion-yolanda" target="_blank" rel="noopener norefferer" href="https://docs.google.com/forms/d/e/1FAIpQLScV06DrBGMfWDlcOCoWOGbjS6FOI1xJy7ax7SiWJsCw2ylYJg/viewform">
                  <img className={classes.bannerAgenda} src={bannerAgenda2} alt="FIRMA EL PETITORIO POR ABSOLUCIÓN PARA YOLANDA" />
                </a>

                {/* <VideoGraph
                  text={"Gabriela Cabezón Camara por la Absolución de Yolanda Vargas"}
                  children={
                    (
                      <video width="auto" height="240" controls>
                        <source
                          src="https://storage.googleapis.com/politicaobrera-187ea.appspot.com/videos/gabriela-cabezon-absolucion-yolanda-vargas.mp4"
                          type="video/mp4"
                        />
                        Tu browser no soporta html video tag
                      </video> 
                    )
                  }
                /> */}
                
                {/*<img className={classes.bannerAgenda} src={bannerAgenda2} alt="Charla debate: La guerra y la crisis mundial. Altamira, Callinicos, García Hernández"/> */}

                {/* <a href="https://w.app/UGqZfc">
                  <img className={classes.bannerAgenda} src={bannerAgenda} alt="Encuentro Nacional Mujer. Contra el gobierno liberticida enemigo de la mujer trabajadora"/>
                </a>  */}
                
                {/*<a href="https://politicaobrera.com/12610-para-derrotar-el-veto-de-milei-el-miercoles-todos-a-congreso">
                  <img className={classes.bannerAgenda} src={bannerAgenda} alt="ABAJO EL VETO DE MILEI. TODOS AL CONGRESO MIERCOLES 11/09"/>
                </a>*/}

                {/* <ImageCarousel key="vertical" data={bannerData2} removeDots={true}/> */}
                
                {/* <img usemap="#suscribemap" className={classes.bannerAgenda} src={bannerAgenda} alt="Suscribite a Política Obrera" />
                <map className={classes.areaLink} name="suscribemap">
                  <area shape="rect" coords="360,381,543,426" alt="suscribite click acá" href="https://api.whatsapp.com/send?phone=5491173600817&text=Me%20quiero%20suscribir%20a%20Pol%C3%ADtica%20Obrera" />
                  <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm" />
                  <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm"/>
                </map> */}
                
                {/* <a key="suscribite-link" target="_blank" rel="noopener norefferer" href="https://api.whatsapp.com/send?phone=5491173600817&text=Me%20quiero%20suscribir%20a%20Pol%C3%ADtica%20Obrera">
                  <img className={classes.bannerAgenda} src={bannerAgenda} alt="Suscribite a Política Obrera" />
                </a> */}

                {/* <video className={classes.bannerAgenda} autoplay controls loop poster={posterbanner}>
                  <source src={videobanner} type="video/mp4"></source>
                </video> */}

                {arrayNotasSecundarias.map((node,i) => (
                  <NotaCardChica key={`notachica-${i}`} node={node} imgFluidDefault={imgFluidDefault} />
                ))}


              </Box>
            </Grid>
          </Grid>
          <Box className={classes.boxContenedor} >
            <div className={classes.masNotasTitulo}>Lee también </div>
            <Grid container spacing={2}>
            {
              ultimas.map((nota,i)=> (
                <Grid key={`seccion-${i}`} item xs={12} className={classes.gridNotaItem} >
                  <Nota node={nota} showSeccion={true} imgFluidDefault={imgFluidDefault} flow="horizontal" />
                </Grid>
              ))
            }
            </Grid>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query ($notasHome:[String]){
    allMarkdownRemark(
      filter: {fields: {slug: {in: $notasHome}}}
    ) {
        totalCount
        edges {
          node {
            frontmatter {
              image
              title
              date
              author
              seccion
              subhead
            }
            fields {
              slug
            }
            excerpt
          }
        }
    }
    file(sourceInstanceName: {eq: "defaults"}, name: {eq: "default1"}) {
        childImageSharp {
            fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    logo:allFile(filter: {sourceInstanceName: {eq: "logo"}, extension: {eq: "png"}, name: {eq: "po-share"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    ultimas: allMarkdownRemark(
      limit: 50,
      filter: {fields: {slug: {nin: $notasHome}}},
      sort: {fields: frontmatter___date, order: DESC}
    ){      
      edges {
        node {
          frontmatter {
            author
            seccion
            title
            date
            image
          }
          fields{
            slug
          }
        }
      }
    }
  }
`
